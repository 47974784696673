import React from "react";
import Factory from "../assets/factory.jpg";
import TrimaxLogo from "../assets/trimax.jpg";
import CottonMax from "../assets/cottonmax.jpg"
import CollUltraMax from "../assets/collultramax.jpg"

const Medical = () => {
  const content = [
    {
      image: TrimaxLogo,
      title: "Trimax",
      description:
        "Trimax is a specially designed tri-laminate fabric for angiography drape. It is integrated patented material offering superior protection against liquid and virus that cause infection diseases.",
      information: ["High efficiency", "Super absorbency", "Integrated"],
      useFor: "Angiography Drape",
      productInfo: [" 110 to 150 gsm  ", "  Tri-Laminate ", "  Blue "],
    },
    {
      image: CottonMax,
      title: "CottonMax",
      description:
        "CottonMax, a patented material, ensures supreme comfort, easy maintenance, and wrinkle resistance, perfect for warmer climates. It maintains your coolness and dryness all day long while prioritizing sustainability, boasting low linting for enhanced safety.",
      information: ["Superior softness", "Breathable", "Low linting"],
      useFor: "Scrub Suit, Patient Gown",
      productInfo: [" 10 to 100 gsm ", " SMMMS ", " Blue Custom Color "],

    },
    {
      image: CollUltraMax,
      title: "CoolTexUltra",
      description:
        "Lightweight material with SMMMS technology providing comfort, breathability, and enhanced protection against static, alcohol, and blood in critical areas.",
      information: ["High water-resistant", "Comfort", "Safety"],
      useFor: ["Isolation Gown, Scrub Suit", "Surgical Gown, Sterilization Wrap"],
      productInfo: [" 110 to 150 gsm  ", "  Tri-Laminate ", "  Blue "],

    },
  ];
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">Medical Products</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {content.map((item, index) => (
          <div key={index} className="flex flex-col rounded-lg shadow-lg overflow-hidden bg-gradient-to-r from-gray-200 to-gray-100 hover:from-gray-100 hover:to-gray-50">
            <img
              className="w-full h-64 object-cover object-center"
              src={item.image}
              alt={item.title}
            />
            <div className="p-6 flex flex-col flex-grow">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">{item.title}</h2>
              <p className="text-gray-600 mb-4">{item.description}</p>
              <ul className="text-gray-600 list-disc ml-6 flex-grow">
                {item.information.map((info, i) => (
                  <li key={i} className="mb-2 flex-shrink-0">{info}</li>
                ))}
              </ul>
              <div className="mt-4 text-center">
                <p className="text-sm text-gray-500">Ideal For: {item.useFor}</p>
              </div>
            </div>
            <div className="p-4 bg-gray-100 text-sm font-semibold text-center">
              <p>Basis Weight Range (gsm): Material: Color(s):</p>
              <p>{item.productInfo}</p>
              {/* <p>Color(s): Blue</p> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Medical;
