import React from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link } from "react-router-dom";
import "../styles/intro.css"; 

const ContactUs = ({id}) => {
  return (
    <div id={id} className="mb-10  bg-[#F3F3F3] md:p-10 p-4">
      <h1 className="font-bold text-4xl text-[#292929] text-center mb-5">
        Contact Us
      </h1>

      <div className="flex justify-around flex-col md:flex-row item-center">
        <div className="md:w-3/4 m-auto">
          <form action="">
            <input
              type="text"
              name="name"
              placeholder="First Name"
              className="border-2 p-3 rounded-[10px] w-full md:w-2/6 pl-3 md:mr-10 my-3 font-semibold bg-[#F3F3F3]"
            />
            <input
              type="text"
              name="lastname"
              placeholder="Last Name"
              className="border-2 p-3 rounded-[10px] w-full md:w-2/6 pl-3 font-semibold bg-[#F3F3F3]"
            />

            <div className="my-3  md:flex">
              <input
                type="text"
                name=""
                placeholder="Topic"
                className="border-2 p-3 rounded-[10px]  w-full md:w-2/6 my-3 pl-3 md:mr-10 font-semibold bg-[#F3F3F3]"
              />
              <div className="flex items-center relative md:w-2/6">
                <input
                  type="text"
                  name="Priority"
                  placeholder="Priority"
                  className="border-2 p-3 rounded-[10px]  w-full pl-3 font-semibold bg-[#F3F3F3]"
                />
                <IoMdArrowDropdown className="absolute right-3" />
              </div>
            </div>

            <textarea
              name=""
              placeholder="Type your Message Here..."
              className="border-2 p-3 rounded-[10px] w-full pl-2 font-semibold md:w-[70%] bg-[#F3F3F3] h-[140px]"
            ></textarea>
          </form>

          <button className="p-2  w-full md:w-[70%] font-bold rounded-[10px] bg-[#f2cb0a] mt-10">
            Send Message
          </button>
        </div>

        <div className="md:w-2/5 mt-10 text-center">
          <div className="border-2  p-5 rounded-[20px]">
            <h1 className="text-2xl font-bold text-[#292929] text-center mb-5">
            Contact Us
            </h1>
            <a
              href="mailto:contact@innovatexpro.com"
              className="font-semibold hover:text-blue-700 text-[#6b7078]"
            >
              contact@innovatexpro.com
            </a>
            <a
              href="tel:+923006478255"
              className=" hover:text-blue-700 font-semibold text-[#6b7078]"
            >
              <p>+923006478255</p>
            </a>
          </div>

          <div className="border-2  p-5 rounded-[20px] mt-10 ">
            <h1 className="text-2xl font-bold text-[#292929] text-center mb-5">
              Our Address
            </h1>
            <p className="font-semibold hover:text-blue-700 text-[#6b7078]">
            GT Road Gujranwala. Pakistan 52250
            </p>
          </div>
          <p className="mt-10 font-bold text-lg">
            Opening Hours: <span className="text-[#f2cb0a]">9AM - 8PM </span>{" "}
            Every Day
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
