import React, { useState } from "react";
import Yarn from "../assets/yarn.jpg"
import pineappleLeather from "../assets/pineappleLeather.jpg"
import appleLeather from "../assets/appleLeather.jpg"
import leafLeather from "../assets/leafLeather.jpg"
import { BsArrowRightCircle } from "react-icons/bs";

const LuxuryContent = [
  {
    title: "Pineapple Leather",
    image: pineappleLeather,
    description: [
      "Piñatex or Pineapple leather is a type of vegan leather made from repurposing pineapple plant leaves, which is considered as agricultural waste.",
      "Dr. Carmen Hijosa invented the fabric after years of research, and ",
      "currently, the rights of Piñatex are owned by her company Ananas Anam, which is a certified B Corp",
      "The only source of this plant-based leather is the long pineapple planet leaves which are left behind after pineapples are harvested. With the help of special machines, long fibers are extracted from these leaves, which are then washed, dried, and purified to remove impurities.",
      "The fluff-like fibers are then blended with a corn-based polylactic acid to achieve a non-woven mesh known as Piñafelt. Rolls of these Piñafelt are then colored using GOTS certified pigments, followed by a resin coating to make the textile strong and durable.",
    ],
  },
  {
    title: "Apple Leather",
    image: appleLeather,
    description: [
      "Apple leather or Apple Skin is a bio-based textile made from the leftover cores and peels from the fruit juice and jam industry. ",
      "Originated in Northern Italy by Frumat, the production process of this vegan leather starts by collecting apple by-products, which contain a good amount of cellulose. The scraps are then crushed, dried, and reduced to a fine powder. After processing, the powder is combined with polyurethane (a synthetic resin) to make the textile strong and waterproof, giving a leather-like feel. And the final result is apple leather.",
      "About 50% of the core material in apple leather is a synthetic resin that is petroleum-based, and the remaining half is apple scraps. So while apple leather is not incredibly eco-friendly, it has a much lower environmental impact compared to cowhide leather, which is the direct competitor of all kinds of vegan leather.",
    ],
  },
  {
    title: "Leaf Leather",
    image: leafLeather,
    description: [
      "Leaf leather is another biobased leather alternative made using teak leaves. This variety of vegan leather is strong, flexible, and water-repellant and features the natural design of teak leaves, making it unique and gorgeous",
      "To produce leaf leather, fallen teak leaves are responsibly collected without harming any tree. ",
      "These leaves are then soaked in water, dyed, and laid flat all together to dry. The drying process binds the leaves, creating large sheets of a leafy material that is further stitched with cotton fabric to provide structure and a soft interior. Finally, a thin BOPP film is applied to make the material waterproof and durable, and leaf leather is ready to be sewn into various accessories.",
    ],
  },
];
const AlternativeLeathers = () => {

  return (
    <div className="mx-auto mb-20">
      <h1 className="heading text-4xl text-[#292929] mb-10 text-center">
        Luxury Alternative Leathers
      </h1>

      <div className="flex flex-wrap md:flex-nowrap w-5/5 justify-center px-2">
        {LuxuryContent.map((card, index) => (
          <div
            key={index}
            style={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
            className="w-full md:max-h-[130rem] md:min-h-[55rem] md:w-[40rem] flex items-center flex-col mt-10 pb-10 mx-4 bordor-bottom border-b-8 rounded-[20px] border-b border-[#f2cb0a]"
          >
            <img
              className="w-4/5 h-[20rem] cursor-pointer rounded-t-[40px] object-cover"
              src={card.image}
              alt={card.title}
            />
            <button className="flex items-center font-extrabold text-xl mt-10">
              {card.title}
            </button>
            <div className="text-[#292929] mt-5 px-8">
              {card.description.map((item, idx) => (
                <p key={idx} className="pt-4  text-[#616161]  leading-20">
                  {item}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlternativeLeathers;
