import React from 'react'
import "../styles/intro.css"; 
import Vison from "../assets/bg2.jpg"
const OurVison = ({id}) => {
  return (
    <div id={id} className='flex justify-between flex-col md:flex-row item-center mb-20'>
        <div className='w-4/4 md:w-[500px] m-auto pl-10' > 
            <h1 className='heading md:text-6xl text-4xl font-bold text-[#292929] mb-10 md:p-1'>Our vision</h1>
            <p className='leading-8 text-[#292929]'>A vision statement is aspirational and expresses your brand’s plan or “vision” for the future and potential impact on the world. They often serve as a guide for a brand’s future goals and explain why customers and employees should stick around for the long haul.</p>
            <p className='font-semibold leading-8 mt-3 text-[#f2cb0a]'>Your company vision is meant to be inspirational while also aligning with the company’s mission. A vision statement should have the following characteristics:</p>
        </div>
        <div className='md:w-2/4 h-[30rem] md:mt-0 mt-8'>
          <img className='w-full h-full object-cover border-r border-t md:rounded-tl-[80px]' src={Vison} alt="" />
        </div>

    </div>
  )
}

export default OurVison