// Home.jsx
import React from 'react';
import Intro from '../Components/Intro';
import TrustedClient from '../Components/TrustedClient';
// import WhatWeDo from '../Components/WhatWeDo';
import AboutUs from '../Components/AboutUs';
import OurVison from '../Components/OurVision';
import OurProducts from '../Components/OurProducts';
import Sports from "../Components/Sports"
import ContactUs from '../Components/ContactUs';
import AlternativeLeathers from '../Components/AlternativeLeathers';
import Medical from '../Components/Medical'
const Home = () => {
  return (
    <>
      <Intro id="intro" />
      <TrustedClient id="trusted" />
      {/* <WhatWeDo id="what-we-do" /> */}
      <AboutUs id="about" />
      <OurVison id="vision" />
      <OurProducts id="products" />
      <Sports id="sports" />
      <AlternativeLeathers/>
      <Medical id="medical" />
      <ContactUs id="contact" />
    </>
  );
};

export default Home;
