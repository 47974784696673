import React from "react";
import { TiTickOutline } from "react-icons/ti";
import { Link, NavLink } from 'react-router-dom'
import { MdOutlineDoubleArrow } from "react-icons/md";
import Factory from "../assets/factory.jpg"
import "../styles/intro.css"; 

const AboutUs = ({id}) => {
  return (
    <div id={id} className="flex justify-between flex-col md:flex-row item-center mb-20">
      <div className="w-5/5 md:w-1/2 md:mb-10 md:m-0 my-10">
        <img
          className="h-[650px] object-cover border-r border-t md:rounded-tr-[80px] "
          src={Factory}
          alt="Factory logo"/>
      </div>
      <div className="m-auto w-5/5 md:w-2/5 p-5 md:p-0">
        <h1 className="heading text-4xl font-extrabold text-[#292929] mb-5">About Us</h1>
        <h1 className="text-lg  text-[#6e6d6a] mb-5">Textile manufacturing (or textile engineering) is a major industry. It is largely based on the conversion of fibre into yarn, then yarn into fabric.</h1>

        <div className="w-4/4 md:w-3/3 mt-10 ">

            <div className="flex w-full  items-center md:justify-around justify-between">
                <div className="bg-[#f2cb0a] p-2 rounded-full">
                    <TiTickOutline className="text-4xl"/>
                </div>
                <div className="w-4/5">
                    <h2 className="text-xl font-extrabold text-[#292929] mb-3">What does a textile factory do?</h2>
                    <p> converts natural or synthetic fibers into yarn or fabric</p>
                </div>
            </div>
        </div>

          <div className=" w-4/4 md:w-3/3  mt-10">
          <div className="flex w-full  items-center md:justify-around justify-between">
                <div className="bg-[#f2cb0a] p-2 rounded-full">
                    <TiTickOutline className="text-4xl"/>
                </div>
                <div className="w-4/5">
                    <h2 className="text-xl font-extrabold text-[#292929] mb-3">What are the basics of textile?</h2>
                    <p> made from fiber, which is formed into yarn, thread, natural silk, rayon, metal wire, etc</p>
                </div>
            </div>
        </div>

        <div className=" w-4/4 md:w-3/3  mt-10">
        <div className="flex w-full  items-center md:justify-around justify-between">
                <div className="bg-[#f2cb0a] p-2 rounded-full">
                    <TiTickOutline className="text-4xl"/>
                </div>
                <div className="w-4/5">
                    <h2 className="text-xl font-extrabold text-[#292929] mb-3">What is the first known textile?</h2>
                    <p>Dzudzuana Cave in the former Soviet state of Georgia</p>
                </div>
            </div>
        </div>


        <div className="w-4/4 md:w-3/3  mt-10">
        <div className="flex w-full  items-center md:justify-around justify-between">
                <div className="bg-[#f2cb0a] p-2 rounded-full">
                    <TiTickOutline className="text-4xl"/>
                </div>
                <div className="w-4/5">
                    <h2 className="text-xl font-extrabold text-[#292929] mb-3">Easy to customize</h2>
                    <p>App has lots of feature now a single that very easily</p>
                </div>
            </div>
        </div>

        <NavLink to="">
            <button className="flex items-center justify-around bg-[#f2cb0a] p-3 rounded-[30px] ml-[10%] mt-10 font-bold w-[160px]">Learned More <MdOutlineDoubleArrow /></button>
        </NavLink>
      </div>
    </div>
  );
};

export default AboutUs;
