import React from "react";
import { NavLink } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import Pent from "../assets/pent.jpg";
import Shoes from "../assets/shoes.jpg";
import Yarn from "../assets/yarn.jpg";
import Treade from "../assets/Thread .jpg";
import Shopper from "..//assets/shopper.jpg";
import Cotton from "../assets/cotton.jpg";
import CottonStitch from "../assets/cottonStitching.jpg";
import "../styles/intro.css";

const OurProducts = ({ id }) => {
  return (
    <div id={id} className=" mb-40">
      <div className="w-full p-2 md:w-3/5 m-auto text-center">
        <h1 className="heading text-5xl font-semibold text-[#292929] mb-10">
          Our Products
        </h1>
        {/* <p>
          TextTile is a leading apparel company in Pakistan having expertise &
          machinery capable of transforming customer ideas into superior quality
          garments, which are taken as the most famous brands in the world.
        </p> */}
      </div>

      <div className="flex flex-wrap md:flex-nowrap w-5/5 items-center justify-center px-2 ">
        <div
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          className="w-[33rem] md:w-[24rem] border rounded-[40px] flex items-center justify-center flex-col pb-10 bg-[FFDC32] mt-10 mx-4"
        >
          <img
            className="w-full h-[20rem] cursor-pointer rounded-t-[40px] object-cover"
            src={Pent}
            alt="jeanse pent"
          />
          <button className="flex items-center font-extrabold text-xl mt-10">
          Recycled Jeans Fabric
          </button>
          {/* <p className="mt-10">InnovaTexPro provides a vast variety of shoe fabric including mesh fabric for sportswear shoes and microfiber fabric for insulation of the shoes.</p> */}
        </div>

       <div
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          className="w-[33rem] md:w-[24rem]  border rounded-[40px] flex items-center justify-center flex-col pb-10 bg-[FFDC32] mt-10 mx-4"
        >
          <img
            className="w-full h-[20rem] cursor-pointer rounded-t-[40px] object-cover"
            src={Shopper}
            alt="jeanse pent"
          />
          <button className="flex items-center font-extrabold text-xl mt-10">
          Shopping Bag Fabric
          </button>
          {/* <p className="mt-10">A vast variety of shopping bags fabric, all manufactured with recycled materials.</p> */}
        </div>

        <div
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          className="w-[33rem] md:w-[24rem]  border rounded-[40px] flex items-center justify-center flex-col pb-10 bg-[FFDC32] mt-10 mx-4"
        >
          <img
            className="w-full h-[20rem] cursor-pointer rounded-t-[40px] object-cover"
            src={Shoes}
            alt="jeanse pent"
          />
          <button className="flex items-center font-extrabold text-xl mt-10">
            Shoe Fabric
          </button>
          {/* <p className="mt-10">InnovaTexPro provides a vast variety of shoe fabric including mesh fabric for sportswear shoes and microfiber fabric for insulation of the shoes.</p> */}
        </div>
      </div>

      <div className="flex flex-wrap md:flex-nowrap w-5/5 items-center justify-center px-2">
        <div
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          className="w-[33rem] md:w-[24rem]  border rounded-[40px] flex items-center justify-center flex-col pb-10 bg-[FFDC32] mt-10 mx-4"
        >
          <img
            className="w-full h-[20rem] cursor-pointer rounded-t-[40px] object-cover"
            src={Treade}
            alt="Warm Fabric"
          />
          <button className="flex items-center font-extrabold text-xl mt-10">
            Stitching Thread
          </button>
        </div>

        <div
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          className="w-[33rem] md:w-[24rem]  border rounded-[40px] flex items-center justify-center flex-col pb-10 bg-[FFDC32] mt-10 mx-4"
        >
          <img
            className="w-full h-[20rem] cursor-pointer rounded-t-[40px] object-cover"
            src={Yarn}
            alt="jeanse pent"
          />
          <button className="flex items-center font-extrabold text-xl mt-10">
            Yarn Fabric
          </button>
        </div>

        <div
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          className="w-[33rem] md:w-[24rem]  border rounded-[40px] flex items-center justify-center flex-col pb-10 bg-[FFDC32] mt-10 mx-4"
        >
          <img
            className="w-full h-[20rem] cursor-pointer rounded-t-[40px] object-cover"
            src={Cotton}
            alt="jeanse pent"
          />
          <button className="flex items-center font-extrabold text-xl mt-10">
            Cotton Fabric
          </button>
        </div>

        <div
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          className="w-[33rem] md:w-[24rem]  border rounded-[40px] flex items-center justify-center flex-col pb-10 bg-[FFDC32] mt-10 mx-4"
        >
          <img
            className="w-full h-[20rem] cursor-pointer rounded-t-[40px] object-cover"
            src={CottonStitch}
            alt="jeanse pent"
          />
          <button className="flex items-center font-extrabold text-xl mt-10">
            Weaving Thread
          </button>
        </div>
      </div>
    </div>
  );
};

export default OurProducts;
