import React from "react";
import Navbar from "./Components/Navbar.tsx";
import 'tailwindcss/tailwind.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from "./Pages/Home.jsx"
import Footer from "./Components/Footer.jsx"

const App =  () => {

return(
  <div className="font-poppins">
  <BrowserRouter>
     <Navbar />
    <Routes>
     <Route path="/" element={<Home />} />
    </Routes>
    <Footer/>
  </BrowserRouter>
  </div>
);
}

export default App;
