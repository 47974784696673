import React from "react";
import Fiber from "../assets/logo (2).png";
import "../styles/intro.css"; 

const Intro = ({id}) => {
  return (

    <div id={id} className="intro-container h-[550px] md:p-[5rem] md:h-[700px]">
      <div className="bg-container h-[500px] md:h-[700px]"></div>
      <div className="p-3 w-4/4 md:w-4/4">
        {/* <h1 className="heading text-4xl md:text-6xl font-extrabold text-white mb-5 md:w-[100%]"><span className="text-[#f2cb0a] text-3xl md:text-7xl">InnovaTexPro</span> Innovative Textile </h1> */}
        <h1 className="heading text-4xl md:text-7xl font-extrabold text-[#f2cb0a]">InnovaTexPro</h1>
        <h1 className="heading text-white text-3xl md:text-6xl font-extrabold mb-5"> Innovative Textile </h1>
        <p className="text-xl font-semibold text-white leading-8 md:w-[500px]">InnovaTexPro is a leading provider of Sustainable and eco friendly fabrics in Pakistan, serving various industries including Fashion, leather, footwear and medical.</p>
      </div>
    </div>


  );
};

export default Intro;
