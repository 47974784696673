import React from "react";
import Logo from "../assets/Logo.png";
import { FaTwitter, FaFacebookF , FaLinkedinIn , FaInstagram , FaReddit   } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="flex flex-wrap md:flex-nowrap justify-around items-center md:h-20 mt">
      <div>
        <p className=" text-center font-semibold">
        InnovaTexPro - &#169; 2024 All Rights Reserved
        </p>
      </div>
      <div className="flex gap-3 md:mt-0 mt-10">
        <Link to="">
          <div
            style={{ border: " 1px solid black" }}
            className="p-3 rounded-full">
            <FaTwitter />
          </div>
        </Link>
        <Link to="">
          <div
            style={{ border: " 1px solid black" }}
            className="p-3 rounded-full">
            <FaFacebookF   />
          </div>
        </Link>
        <Link to="">
          <div
            style={{ border: " 1px solid black" }}
            className="p-3 rounded-full">
            <FaLinkedinIn />
          </div>
        </Link>
        <Link to="">
          <div
            style={{ border: " 1px solid black" }}
            className="p-3 rounded-full">
            <FaInstagram   />
          </div>
        </Link>
      </div>
      <style jsx>{`
        footer {
          position: absolute;
          // bottom: 0;
          width: 100%;
        }
      `}</style>
    </footer>
  );
};

export default Footer;
