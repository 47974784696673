import React from 'react'

const TrustedClient = ({id}) => {
  return (
    <div id={id} className='flex justify-center items-center gap-5 mt-20 mb-20 flex-wrap md:flex-nowrap'>
        <div className='text-center w-2/5'>
            <img className='w-4/4' src="https://wpstaging.a2zcreatorz.com/Gadoon/Design/v3/wp-content/uploads/2023/06/lucky-exim.png" alt="" />
        </div>
        <div className='text-center  w-2/5'>
            <img className='w-4/4' src="https://wpstaging.a2zcreatorz.com/Gadoon/Design/v3/wp-content/uploads/2023/06/KN.png" alt="" />
        </div>
        <div className='text-center w-2/5'>
            <img className='w-4/4' src="https://wpstaging.a2zcreatorz.com/Gadoon/Design/v3/wp-content/uploads/2023/02/Tabba-kidney-instiute.png" alt="" />
        </div>
        <div className='text-center  w-2/5'>
            <img className='w-4/4' src="https://wpstaging.a2zcreatorz.com/Gadoon/Design/v3/wp-content/uploads/2023/02/Luckyone.png" alt="" />
        </div>
        <div className='text-center  w-2/5'>
            <img className='w-4/4' src="https://wpstaging.a2zcreatorz.com/Gadoon/Design/v3/wp-content/uploads/2023/02/ATF.png" alt="" />
        </div>

        <div className='text-center  w-2/5'>
            <img className='w-4/4' src="https://wpstaging.a2zcreatorz.com/Gadoon/Design/v3/wp-content/uploads/2023/06/younus-1.png" alt="" />
        </div>
    </div>
  )
}

export default TrustedClient