import React from "react";
import "../styles/intro.css";
import Vison from "../assets/bg2.jpg";
import Pent from "../assets/pent.jpg";
import "../styles/intro.css";
import SwinWear from "../assets/yoga.jpg"
import SportsWear from "../assets/sportsWear.jpg"
import Yoga from "../assets/yogaWear.jpg"
const Sports = () => {
  const cardContent = [
    {
      title: "Sportswear Fabric",
      image: SportsWear,
      description: [
        "Unparalleled Comfort: Our sportswear fabric is designed with the utmost comfort in mind, providing a luxurious feel against the skin",
        "Performance Enhancement: Engineered to enhance your athletic performance, our fabric offers exceptional elasticity and flexibility, allowing for unrestricted movement.",
        "Breathability at its Best: Stay cool and dry during intense workouts with our breathable fabric, which efficiently wicks away moisture and promotes airflow.",
        "Customizable and Convenient: With our sportswear fabric, you have the freedom to customize your designs to reflect your unique style. Additionally, our one-stop service ensures a smooth and hassle-free experience from order to delivery.",
      ],
    },
    {
      title: "Yoga wear Fabric",
      image: Yoga,
      description: [
        "Premium Comfort: Our yoga wear fabric is specially designed to provide ultimate comfort during your yoga sessions.",
        "Luxuriously Lightweight: Experience a lightweight and luxurious feel with our yoga wear fabric, allowing you to move effortlessly.",
        "Enhanced Performance: The fabric's elasticity and flexibility ensure maximum performance and freedom of movement during yoga practice.",
        "Breathable and Moisture-Wicking: Stay cool and dry with our moisture-wicking fabric, ensuring sweat is quickly absorbed and evaporated.",
      ],
    },
    {
      title: "Swimwear Fabric",
      image: SwinWear,
      description: [
        "Superior Comfort: Our swimwear fabric is designed for maximum comfort, allowing you to move freely in the water.",
        "Excellent Elasticity: With exceptional stretchability, our swimwear fabric ensures a perfect fit and provides excellent support for all body types.",
        "Breathable and Waterproof: Made with breathable materials, our swimwear fabric allows air to circulate, keeping you cool and dry. Its waterproof properties also ensure quick drying after water activities.",
        "UV Protection: Our swimwear fabric is specially designed to offer maximum protection against harmful UV rays, ensuring your skin stays safe during outdoor water activities.",
      ],
    },
  ];

  return (
    <div className="mb-20">
    <h1 className="heading text-4xl font-semibold text-[#292929] text-center">
      Sportswear Fabric
    </h1>
    <div className="flex flex-wrap md:flex-nowrap w-5/5 justify-center px-2">
      {cardContent.map((card, index) => (
        <div
          key={index}
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          className="w-full md:w-[40rem] h-4/4 border rounded-[40px] flex items-center flex-col pb-10 bg-[FFDC32] mt-10 mx-4"
        >
          <img
            className="w-full h-[20rem] cursor-pointer rounded-t-[40px] object-cover"
            src={card.image}
            alt={card.title}
          />
          <button className="flex items-center font-extrabold text-xl mt-10">
            {card.title}
          </button>
          <div className="text-[#292929] mt-5 px-8">
            <ul className="list-disc">
              {card.description.map((item, idx) => (
                <li key={idx} className="pt-4 text-[#616161] pl-2">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  </div>
  );
};

export default Sports;