import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Logo from '../assets/Logo.png';
import { HashLink as Link } from 'react-router-hash-link';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [showLinks, setShowLinks] = useState(true);
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
        setNav(false);
      }
    };

    const handleScroll = () => {
      setNav(false); // Close the navbar when the user scrolls
    };

    // Show/hide navigation links and drawer based on screen size
    const handleResize = () => {
      if (window.innerWidth > 1187) {
        setShowLinks(true);
      } else {
        setShowLinks(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    window.addEventListener('scroll', handleScroll); // Listen for scroll events
    window.addEventListener('resize', handleResize); // Listen for window resize events

    // Initial check for window size on mount
    handleResize();

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('scroll', handleScroll); // Cleanup scroll event listener
      window.removeEventListener('resize', handleResize); // Cleanup resize event listener
    };
  }, []);

  const handleNav = () => {
    setNav(!nav);
  };

  const closeNav = () => {
    setNav(false);
  };

  const navItems = [
    { id: 1, text: 'Home', url: '#intro' },
    { id: 2, text: 'What We Do', url: '#what-we-do' },
    { id: 3, text: 'About Us', url: '#about' },
    { id: 4, text: 'Products', url: '#products' },
    { id: 5, text: 'Our Vision', url: '#vision' },
    { id: 6, text: 'Contact', url: '#contact' },
  ];

  return (
    <div ref={navbarRef} className='bg-[#f2cb0a] flex justify-between items-center mx-auto px-4 fixed w-full z-50'>
      <div>
        <img src={Logo} alt="logo" className="md:w-[60%] w-2/4 object-contain" />
      </div>

      {/* Desktop Navigation */}
      {showLinks && (
        <ul className='hidden md:flex md:justify-end space-x-6 w-full'>
          {navItems.map(item => (
            <Link smooth to={item.url} key={item.id}>
              <li className='p-2 hover:bg-black rounded-xl m-2 cursor-pointer duration-300 hover:text-white text-lg'>
                {item.text}
              </li>
            </Link>
          ))}
        </ul>
      )}

      {/* Mobile Navigation Icon */}
      {!showLinks && (
        <div onClick={handleNav} className='block'>
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
      )}

      {/* Mobile Navigation Drawer */}
      {!showLinks && (
        <ul
          className={
            nav
              ? 'fixed left-0 top-0 w-3/4 h-full shadow-lg bg-[#f2cb0a] text-black ease-in-out duration-500 z-50'
              : 'ease-in-out w-3/4 duration-500 fixed top-0 bottom-0 left-[-100%]'
          }
        >
          <div className='mb-10'>
            <img src={Logo} alt="logo" className="w-2/4 object-contain"/>
          </div>

          {/* Mobile Navigation Items */}
          {navItems.map(item => (
            <Link smooth to={item.url} key={item.id} onClick={closeNav}>
              <li
                className='p-4 border-b hover:bg-[#000] duration-300 hover:text-white cursor-pointer border-gray-600'
                >
                {item.text}
              </li>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Navbar;
